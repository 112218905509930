import "./bubblePopUp.scss"

const BubblePopUp = ({handleClose, popData, popInvert})=>( 

    <div className="BubblePopUp">
    <div className={`dialog ${popInvert?"invert":""}`}>
        <a href="#close" title="Close" class="close" onClick={()=>{handleClose(popData)}}>X</a>
        <p className="popup-heading"><b>{popData.tittle}</b></p>
		<p className="popup-content">{popData.content}</p>
    </div>
    </div>

)

export default BubblePopUp;