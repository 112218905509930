export const LANG = {
  ENGLISH: 1,
  PORTUGESE: 2,
  FRENCH: 3,
  DUTCH: 4,
  GERMAN: 5,
  LITHUANIAN: 6,
  LATVIAN: 7,
  ESTONIAN: 8,
  SWEDISH: 9,
  NORWEGIAN: 10,
};

export const PAGE = {
  LAYOUT: 0,
  ABOUT: 1,
  PRODUCT: 2,
  SCIENCE: 3,
  LEARN: 4,
};

export const APPROVAL_CODE = {
  NORWAY: "CH-20221130-79",
  SWEDEN: "CH-20221130-78",
  ESTONIA: "CH-20230116-48",
  LATVIA: "CH-20230116-47",
  LITHUANIA: "CH-20230123-21",
};
