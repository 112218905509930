import { NavLink } from "react-router-dom";
import "./BottomNavigation.scss";
import { ReactComponent as AboutIcon } from "./../../images/svg/About.svg";
import { ReactComponent as ProductIcon } from "./../../images/svg/Product.svg";
import { ReactComponent as ScienceIcon } from "./../../images/svg/Science.svg";
import { ReactComponent as LearnIcon } from "./../../images/svg/Learn.svg";

const BottomNavigation = (props) => {
  return (
    <div
      className={`navigation-container ${
        !props.showApproveCode ? "no-code-bot" : ""
      }`}
    >
      <div className="nav-wrapper">
        <div className="menu-link">
          <NavLink to={`/`}>
            <span className="link-icon">
              <AboutIcon />
            </span>
            <span className="link-text">{props.navLabels.About}</span>
          </NavLink>
        </div>
        <div className="menu-link">
          <NavLink to={`/product`}>
            <span className="link-icon">
              <ProductIcon />
            </span>
            <span className="link-text">{props.navLabels.Product}</span>
          </NavLink>
        </div>
        <div className="menu-link">
          <NavLink to={`/science`}>
            <span className="link-icon">
              <ScienceIcon />
            </span>
            <span className="link-text">{props.navLabels.Science}</span>
          </NavLink>
        </div>
        <div className="menu-link">
          <NavLink to={`/learn`}>
            <span className="link-icon">
              <LearnIcon />
            </span>
            <span className="link-text">{props.navLabels.Learn}</span>
          </NavLink>
        </div>
      </div>

      {props.showApproveCode && (
        <p className="bp-approval">
          {props.approvalText ? "" : "Approval Code - "} {props.approvalCode}
        </p>
      )}
    </div>
  );
};

export default BottomNavigation;
