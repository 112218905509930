import "./sensiTable.scss";


const SensiTable = ({tableImage})=>( 
    <div className="sensi-table">
        <div className="row">
            <img src={tableImage} alt="" srcset=""  className="img-fluid"/>
        </div>
    </div>
)

export default SensiTable;