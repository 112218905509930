import { useContext } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";

import "./Accordion.style.scss";
import Accordion from "react-bootstrap/Accordion";

import { ReactComponent as PlusIcon } from "./../../../images/svg/Accordion_Icon_Plus.svg";
import { ReactComponent as MinusIcon } from "./../../../images/svg/Accordion_Icon_Minus.svg";

function CustomToggle({ children, eventKey, callback, tittle }) {
  // const [toggleAccord, setToggleAccord] = useState(false);
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isExpand = activeEventKey === eventKey;
  return (
    <div className="accordion-header" onClick={decoratedOnClick}>
      <span className="header-icon">
        {isExpand ? <MinusIcon /> : <PlusIcon />}
      </span>
      <h2>{tittle}</h2>
    </div>
  );
}

const Aaccordion = () => {
  return (
    <>
      <Accordion defaultActiveKey="3" alwaysopen>
        <div className="accordion-content">
          <CustomToggle
            eventKey="3"
            tittle={"SensiControl Moisturizing cream:"}
          />
          <Accordion.Collapse eventKey="3">
            <div className="accordion-body">
              <p>Suitable for: </p>
              <p>Between Flares - Everyday use for the whole day. </p>
              <p>During Flares – Everyday use on non-lessional skin.</p>
              <p>
                SensiControl’s Unique Derma Defense Formula protects the skins
                barrier in four ways to keep skin free from flares for 3 months
              </p>

              <ul>
                <li>
                  Prebiotics: Support healthy microflora to protect your skin
                </li>
                <li>
                  Natural oils, vitamin B3, Pro Vitamin B5: Support the skin’s
                  natural barrier for long lasting protection
                </li>
                <li>Lamellar Lipids: further strengthen the skin barrier</li>
                <li>Moisturizes: for long lasting moisturization</li>
              </ul>
              <p>Benefits</p>
              <p>Proactive long-term control for eczema-prone skin</p>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
      <Accordion>
        <div className="accordion-content">
          <CustomToggle eventKey="4" tittle={"SensiCalm"} />
          <Accordion.Collapse eventKey="4">
            <div className="accordion-body">
              <p>Suitable for: </p>
              <p>During Flares – Targeted Spot Treatment for lesional skin</p>
              <p>
                Bepanthen SensiCalm is a cortisone-free formula, which combines
                fast soothing of itch and regeneration of the skin barrier. It
                relieves itch quickly by imitating skin in three ways:
              </p>

              <ul>
                <li>
                  By providing lipids (eg. Ceramides) in lamellas like in the
                  skin barrier thanks to the lipid lamellar technology
                </li>
                <li>
                  By providing physiologic humectants for increased
                  moisturization
                </li>
                <li>By providing natural caring lipids</li>
              </ul>
              <p>Benefits</p>
              <p>relieves itch within 30 minutes after application</p>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </>
  );
};

export default Aaccordion;
