import "./Model.style.scss";

import { useContext, useState } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

import { LANG } from "../../../config/CONST";

import { CountryContext } from "../../../Contexts/country.context";

import flagProtugal from "../../../images/Portugal-flag.png";
import flagBelgium from "../../../images/Belgium-flag.png";
import flagEstonia from "../../../images/Estonia-flag.png";
import flagLatvia from "../../../images/Latvia-flag.png";
import flagLithuania from "../../../images/Lithuania-flag.png";
import flagLuxembourg from "../../../images/Luxembourg-flag.png";
import flagNorway from "../../../images/Norway-flag.png";
import flagSweden from "../../../images/Sweden-flag.png";

import { ReactComponent as Tick } from "./../../../images/svg/Tick.svg";

const BpModal = ({ show, handleClose, ...props }) => {
  const CountrySelect = [
    {
      countryId: 1,
      countryName: "Portugal",
      flag: flagProtugal,
      isCurrent: false,
      countryCode:"POR",
      defLang:LANG.PORTUGESE
    },
    {
      countryId: 2,
      countryName: "Belgium",
      flag: flagBelgium,
      isCurrent: false,
      countryCode:"BEL",
      defLang:LANG.FRENCH
    },
    {
      countryId: 3,
      countryName: "Latvia",
      flag: flagLatvia,
      isCurrent: false,
      countryCode:"LVA",
      defLang:LANG.LATVIAN
    },
    {
      countryId: 4,
      countryName: "Lithuania",
      flag: flagLithuania,
      isCurrent: false,
      countryCode:"LTU",
      defLang:LANG.LITHUANIAN
    },
    {
      countryId: 5,
      countryName: "Estonia",
      flag: flagEstonia,
      isCurrent: false,
      countryCode:"EST",
      defLang:LANG.ESTONIAN
    },
    {
      countryId: 6,
      countryName: "Luxembourg",
      flag: flagLuxembourg,
      isCurrent: false,
      countryCode:"LUX",
      defLang:LANG.GERMAN
    },
    {
      countryId: 7,
      countryName: "Sweden",
      flag: flagSweden,
      isCurrent: false,
      countryCode:"SWE",
      defLang:LANG.SWEDISH
    },
    {
      countryId: 8,
      countryName: "Norway",
      flag: flagNorway,
      isCurrent: false,
      countryCode:"NOR",
      defLang:LANG.NORWEGIAN
    },
  ];

  const [countryList, setSelectCountry] = useState(CountrySelect);
  const [closeCountry, setCloseCountry] = useState(true);
  const { setCurrentCountry } = useContext(CountryContext);

  const handleCountrySelect = (currentCountryId, currentCountryCode, currentLang ) => {
    setSelectCountry((prevCountryList) => {

      return prevCountryList.map((country) => {
        country.isCurrent = false;
        if (country.countryId === currentCountryId) {
          country.isCurrent = !country.isCurrent;
          return country;
        } else {
          return country;
        }
      });
    });

    setCurrentCountry({ LANG: currentLang, COUNTRY: currentCountryCode });

    setCloseCountry(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} keyboard={false} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush">
            {countryList.map((list) => (
              <ListGroup.Item
                key={list.countryId}
                onClick={() => handleCountrySelect(list.countryId,list.countryCode,list.defLang)}
              >
                <div className="coutry-row-wraper">
                  <div className="coutry-item">
                    <img src={list.flag} alt="" />
                  </div>
                  <div className="coutry-item">{list.countryName}</div>
                  <div className="coutry-item">
                    {list.isCurrent && <Tick />}
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose} disabled={closeCountry}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BpModal;
