import "./Product.view.style.scss";
import { useEffect, useState, useContext } from "react";

import UpCurve from "./../../components/UpCurve/UpCurve.components";
import DownCurve from "./../../components/DownCurve/DownCurve.components";

import Aaccordion from "./../../components/Accordion/Prduct/Accordion.component";
import productItem from "../../images/ProductMain.png";
import mngmentImg from "../../images/Management.png";
import productConsumerPie1 from "../../images/Product-Pie-1.png";
import productConsumerPie2 from "../../images/Product-Pie-2.png";

import { CountryContext } from "./../../Contexts/country.context";
import { TraslationService } from "./../../Service/Language/TranslationService";
import { PAGE } from "./../../config/CONST";

const Product = () => {
  const [animate, setAnimate] = useState("");
  const { currentCountry } = useContext(CountryContext);

  let TS = new TraslationService(currentCountry.LANG, PAGE.PRODUCT);
  const ProductLabels = TS.getTranslation();

  useEffect(() => {
    setAnimate("atop");
  }, []);
  return (
    <div className="product-container">
      {/* <div className="product-brand-title">
        <h2>The</h2>
        <h2 className="title-bold">Bepanthen</h2>
        <h2>Difference</h2>
      </div> */}
      {/* <div className="product-spacer"></div> */}
      {/* <UpCurve /> */}
      {/* <img src={mngmentImg} className="img-fluid" alt="Sensi control" /> */}
      {/* <div className="product-item-container">
        <img src={mngmentImg} className="img-fluid" alt="Sensi control" />
      </div> */}
      <DownCurve />
      <div className="product-brand-title">
        <h2 className="title-bold">{ProductLabels.ProductMainHeading}</h2>
      </div>
      <div className="product-plain-content">
        <p>{ProductLabels.ProductBenefit1}</p>
        <p>{ProductLabels.ProductBenefit2}</p>
        <p>{ProductLabels.ProductBenefit3}</p>
        <ol>
          <li>{ProductLabels.ProductBenefit3Sublist[0].text}</li>
          <ol className="list-second">
            {ProductLabels.ProductBenefit3Sublist[0].list.length > 0 &&
              ProductLabels.ProductBenefit3Sublist[0].list.map((s) => (
                <li>{s}</li>
              ))}
          </ol>
          {ProductLabels.ProductBenefit3Sublist[1].text !== "" && (
            <li>{ProductLabels.ProductBenefit3Sublist[1].text}</li>
          )}
        </ol>
        <p>{ProductLabels.ProductBenefit4}</p>
      </div>
      <UpCurve />
      {/* <Aaccordion /> */}
      {/* <DownCurve /> */}
      {/* <div className="product-brand-title">
        <h2 className="title-bold">Consumer</h2>
        <div className="product-pie-container">
          <div className="product-consumer-row">
            <div className="product-pie">
              <img src={productConsumerPie1} alt="product consumer chart" />
            </div>
            <div className="product-data">
              <p>
                The use of the combination of Bepanthen SensiControl Daily
                Gentle Body Wash and Bepanthen SensiControl cream they feel in
                control of their baby’s/child’s sensitive/eczema prone skin
              </p>
            </div>
          </div>
          <div className="product-consumer-row no-border">
            <div className="product-pie">
              <img src={productConsumerPie2} alt="product consumer chart" />
            </div>
            <div className="product-data">
              <p>
                The combination of Bepanthen SensiControl Daily Gentle Body Wash
                and Bepanthen SensiControl cream provides optimal care for their
                baby’s/child/s sensitive/eczema prone skin
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default Product;
