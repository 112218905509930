import "./Accordion.style.scss";
import Accordion from "react-bootstrap/Accordion";
import { ReactComponent as PlusIcon } from "./../../../images/svg/Accordion_Icon_Plus.svg";
import { ReactComponent as MinusIcon } from "./../../../images/svg/Accordion_Icon_Minus.svg";
import ingredientsImg1 from "./../../../images/ingredients1.png";
import ingredientsImg2 from "./../../../images/ingredients2.png";
import pieScore1 from "./../../../images/consumer-pie1.png";
import pieScore2 from "./../../../images/consumer-pie2.png";
import pieScore3 from "./../../../images/consumer-pie3.png";
import pieScore4 from "./../../../images/consumer-pie4.png";

import { useContext } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";

function CustomToggle({ children, eventKey, callback, tittle }) {
  // const [toggleAccord, setToggleAccord] = useState(false);
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isExpand = activeEventKey === eventKey;
  return (
    <div className="accordion-header" onClick={decoratedOnClick}>
      <span className="header-icon">
        {isExpand ? <MinusIcon /> : <PlusIcon />}
      </span>
      <h2>{tittle}</h2>
    </div>
  );
}

const Aaccordion = ({ data, ...props }) => {
  return (
    <>
      <Accordion defaultActiveKey="0" alwaysopen>
        <div className="accordion-content">
          <CustomToggle eventKey="0" tittle={data.RecomendedLabel} />
          <Accordion.Collapse eventKey="0">
            <div className="accordion-body">{data.RecomendedText}</div>
          </Accordion.Collapse>
        </div>
      </Accordion>
      <Accordion>
        <div className="accordion-content">
          <CustomToggle eventKey="1" tittle={data.IngredientsLabel} />
          <Accordion.Collapse eventKey="1">
            <div className="accordion-body">
              <div>
                <div className="abt-ing-img">
                  <img src={ingredientsImg1} alt="ingredients" />
                  <img src={ingredientsImg2} alt="ingredients" />
                </div>
                <div>
                  {data.IngredientsText.split("/n").map((s) => (
                    <p>{s}</p>
                  ))}
                  <ul>
                    {data.IngredientsTextList.map((s) => (
                      <li>
                        {s.split("/n")[0]}
                        <p>{s.split("/n")[1]}</p>
                      </li>
                    ))}
                  </ul>

                  {data.IngredientsListTitle}
                  <ul>
                    {data.IngredientsList.map((s) => (
                      <li>{s}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
      <Accordion>
        <div className="accordion-content">
          <CustomToggle eventKey="1" tittle={data.HowToUseLabel} />
          <Accordion.Collapse eventKey="1">
            <div className="accordion-body">
              {data.HowToUseText.split("/n").map((s) => (
                <p
                  dangerouslySetInnerHTML={{
                    __html: s,
                  }}
                ></p>
              ))}
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
      <Accordion>
        <div className="accordion-content">
          <CustomToggle eventKey="2" tittle={data.ConsumerAdultTextHeading} />
          <Accordion.Collapse eventKey="2" className="col-md-12 col-sm-12">
            <div className="accordion-body">
              {/* <p>{data.ConsumerAdultTextHeading}</p> */}
              <div class="pie-data-wrapper">
                <div className="pie-data-block">
                  <div className="pie-bloack">
                    <img src={pieScore1} alt="" />
                  </div>
                  <div className="data-bloack">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data.ConsumerAdultText[0],
                      }}
                    ></p>
                  </div>
                </div>
                <div className="pie-data-block">
                  <div className="pie-bloack">
                    <img src={pieScore2} alt="" />
                  </div>
                  <div className="data-bloack">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data.ConsumerAdultText[1],
                      }}
                    ></p>
                  </div>
                </div>
                <div className="pie-data-block">
                  <div className="pie-bloack">
                    <img src={pieScore1} alt="" />
                  </div>
                  <div className="data-bloack">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data.ConsumerAdultText[2],
                      }}
                    ></p>
                  </div>
                </div>
                <div className="pie-data-block">
                  <div className="pie-bloack">
                    <img src={pieScore3} alt="" />
                  </div>
                  <div className="data-bloack">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data.ConsumerAdultText[3],
                      }}
                    ></p>
                  </div>
                </div>
                <div className="pie-data-block no-border">
                  <div className="pie-bloack ">
                    <img src={pieScore3} alt="" />
                  </div>
                  <div className="data-bloack">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data.ConsumerAdultText[4],
                      }}
                    ></p>
                  </div>
                </div>
              </div>
              <br />
              {props.showLab && (
                <p>*Internal report IMP 21763 Bayer Consumer Health</p>
              )}
              {/* <div class="pie-data-wrapper no-border">
                <div className="pie-data-block">
                  <div className="pie-bloack">
                    <img src={pieScore3} alt="" />
                  </div>
                  <div className="data-bloack">
                    <p>{data.ConsumerChildText[0]}</p>
                  </div>
                </div>
                <div className="pie-data-block no-border">
                  <div className="pie-bloack ">
                    <img src={pieScore4} alt="" />
                  </div>
                  <div className="data-bloack">
                    <p>{data.ConsumerChildText[1]}</p>
                  </div>
                </div>
              </div> */}
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </>
  );
};

export default Aaccordion;
