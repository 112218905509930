import "./Science.view.style.scss";
import { useContext } from "react";
import UpCurve from "./../../components/UpCurve/UpCurve.components";
import SensiTable from "./../../components/SensiTable/SensiTable.component";
import SensiWashBubble from "../../components/SensiWashBubbles/Bubble.component";

//POR
import SensiWashTablePor from "./../../images/scienceImages/Portugese/PortugueseTable.png";
import topImgPor from "./../../images/scienceImages/Portugese/PortugueseTop.png";
import botImgPor from "./../../images/scienceImages/Portugese/PortugueseBottom.png";

//Dutch
import SensiWashTableDutch from "./../../images/scienceImages/Dutch/DutchTable.png";
import topImgDutch from "./../../images/scienceImages/Dutch/DutchTop.png";
import botImgDutch from "./../../images/scienceImages/Dutch/DutchBottom.png";

//French
import SensiWashTableFrench from "./../../images/scienceImages/French/FrenchTable.png";
import topImgDutchFrench from "./../../images/scienceImages/French/FrenchTop.png";
import botImgDutchFrench from "./../../images/scienceImages/French/FrenchBot.png";

//Estonian
import SensiWashTableEstonia from "./../../images/scienceImages/Estonian/EstonianTable.png";
import topImgEstonia from "./../../images/scienceImages/Estonian/EstonianTop.png";
import botImgEstonia from "./../../images/scienceImages/Estonian/EstonianBot.png";

//Latvian
import SensiWashTableLatvia from "./../../images/scienceImages/Latvian/LatvianTable.png";
import topImgLatvia from "./../../images/scienceImages/Latvian/LatvianTop.png";
import botImgLatvia from "./../../images/scienceImages/Latvian/LatvianBot.png";

//Lithuanian
import SensiWashTableLith from "./../../images/scienceImages/Lithuanian/LithuanianTable.png";
import topImgLith from "./../../images/scienceImages/Lithuanian/LithuanianTop.png";
import botImgLith from "./../../images/scienceImages/Lithuanian/LithuanianBot.png";

//Swedish
import SensiWashTableSwd from "./../../images/scienceImages/Swedish/SwedishTable.png";
import topImgSwd from "./../../images/scienceImages/Swedish/SwedishTop.png";
import botImgSwd from "./../../images/scienceImages/Swedish/SwedishBottom_New.png";

//Norwegian
import SensiWashTableNor from "./../../images/scienceImages/Norweigan/NorweiganTable_New.png";
import topImgNor from "./../../images/scienceImages/Norweigan/NorweiganTop.png";
import botImgNor from "./../../images/scienceImages/Norweigan/NorweiganBottom.png";

import { CountryContext } from "./../../Contexts/country.context";
import { TraslationService } from "./../../Service/Language/TranslationService";
import { PAGE, LANG } from "./../../config/CONST";

const Science = () => {
  const { currentCountry } = useContext(CountryContext);
  let TS = new TraslationService(currentCountry.LANG, PAGE.SCIENCE);
  const bubbleData = TS.getTranslation();

  let topImg = topImgPor;
  let botImg = botImgPor;
  let SensiWashTable = SensiWashTablePor;
  let showTable = true; // flag to controle visibility of table, as Sweden don't have table.
  switch (currentCountry.LANG) {
    case LANG.PORTUGESE:
      topImg = topImgPor;
      botImg = botImgPor;
      SensiWashTable = SensiWashTablePor;
      break;

    case LANG.DUTCH:
      topImg = topImgDutch;
      botImg = botImgDutch;
      SensiWashTable = SensiWashTableDutch;
      break;

    case LANG.FRENCH:
      topImg = topImgDutchFrench;
      botImg = botImgDutchFrench;
      SensiWashTable = SensiWashTableFrench;
      break;

    case LANG.ESTONIAN:
      topImg = topImgEstonia;
      botImg = botImgEstonia;
      SensiWashTable = SensiWashTableEstonia;
      break;

    case LANG.LATVIAN:
      topImg = topImgLatvia;
      botImg = botImgLatvia;
      SensiWashTable = SensiWashTableLatvia;
      break;

    case LANG.LITHUANIAN:
      topImg = topImgLith;
      botImg = botImgLith;
      SensiWashTable = SensiWashTableLith;
      break;
    case LANG.SWEDISH:
      topImg = topImgSwd;
      botImg = botImgSwd;
      showTable = false;
      SensiWashTable = SensiWashTableSwd;
      break;
    case LANG.NORWEGIAN:
      topImg = topImgNor;
      botImg = botImgNor;
      showTable = false;
      SensiWashTable = SensiWashTableNor;
      break;
    default:
      topImg = topImgDutchFrench;
      botImg = botImgDutchFrench;
      SensiWashTable = SensiWashTableFrench;
      break;
  }

  return (
    <div className="science-view-container">
      {/* <div className="science-title">
        <h2>The Science</h2>
      </div> */}
      {/* <UpCurve /> */}
      {/* <ScienceAccordion /> */}
      {/* <SensiCreamBubble />
    <SensiTable tableImage={SensiCreamTableimg} /> */}
      <SensiWashBubble
        bubblewashData={bubbleData}
        bubbleBackTop={topImg}
        bubbleBackBot={botImg}
      />
      {showTable && <SensiTable tableImage={SensiWashTable} />}
    </div>
  );
};

export default Science;
