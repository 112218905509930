import { useState } from "react";

import "./washBubble.scss";
import BubblePopUp from "./../BubblePopUp/BubblePopUp.component";

import bblBlue from "./../../images/bbl-blue.png";
import bblBluePale from "./../../images/bbl-blue-pale.png";
import bblePurple from "./../../images/bble-purple.png";
import bblePurplePale from "./../../images/bbl-purple-pale.png";
import bblOrange from "./../../images/bbl-orange.png";
import bblRed from "./../../images/bbl-red.png";

const SensiWashBubble = ({bubblewashData,bubbleBackTop,bubbleBackBot})=>{

    const [bubblePopShow, setBubblePopShow] = useState(false);
    const [bubbleGrayPopShow, setBubbleGrayPopShow] = useState(false);
    const [bubblePopData, setBubblePopData] = useState(null);
    const [bubbleGrayPopData, setBubbleGrayPopData] = useState(null);

    const handleBubblePopShow = (bubbleId,isGray)=>{
        // reset all bubble avtive state before new selection
        for (const bubble in bubblewashData) {
            bubblewashData[bubble].isActive = false;
        }

        switch (bubbleId) {
            case "B1": 
            bubblewashData.B1.isActive = true;
            setBubblePopData(bubblewashData.B1);              
                break;
            case "B2": 
            bubblewashData.B2.isActive = true;
            setBubblePopData(bubblewashData.B2);               
                break;
            case "B3": 
            bubblewashData.B3.isActive = true;
            setBubblePopData(bubblewashData.B3);               
                break;
            case "B4": 
            bubblewashData.B4.isActive = true;
            setBubblePopData(bubblewashData.B4);               
                break;
            case "B5": 
            bubblewashData.B5.isActive = true;
            setBubblePopData(bubblewashData.B5);               
                break;
            case "B6": 
            bubblewashData.B6.isActive = true;
            setBubbleGrayPopData(bubblewashData.B6);               
                break;
            case "B7": 
            bubblewashData.B7.isActive = true;
            setBubbleGrayPopData(bubblewashData.B7);
                break;        
            default:
                setBubblePopData(null);  
                break;
        }
        if (isGray) {
            setBubbleGrayPopShow(true);
        } else {
            setBubblePopShow(true);
        }        
    }


    const handleBubblePopHide = (msg)=>{
        bubblewashData[msg.id].isActive = false;
        setBubblePopShow(false);
    }

    const handleBubbleGrayPopHide = (msg)=>{
        bubblewashData[msg.id].isActive = false;
        setBubbleGrayPopShow(false);
    }


    return (
    <div className="sensi-wash-container">
       
        <div className="row ">
            <div className="sensi-wash-content">
                <img src={bubbleBackTop} alt="" srcset=""  className="img-fluid sensi-wash-back-top"/>
                <div className="bubble-row bble--bot-grid-pos">
                    <div className="col"> <img src={bblePurplePale} alt="Sensiwash Base" className={`img-fluid sensi-wash-bubble ${bubblewashData["B1"].isActive?"bubble-active":""}`} onClick={()=>handleBubblePopShow("B1",false)}/></div>
                    <div className="col"> <img src={bblBluePale} alt="Sensiwash Base" className={`img-fluid sensi-wash-bubble ${bubblewashData["B2"].isActive?"bubble-active":""}`}onClick={()=>handleBubblePopShow("B2",false)} /></div>
                    <div className="col"> <img src={bblBlue} alt="Sensiwash Base" className={`img-fluid sensi-wash-bubble last-bubble-pad  ${bubblewashData["B3"].isActive?"bubble-active":""}`} onClick={()=>handleBubblePopShow("B3",false)} /></div>
                </div>   
                <div className="bubble-row bble--top-grid-pos">
                    <div className="col"> <img src={bblRed} alt="Sensiwash Base" className={`img-fluid sensi-wash-bubble ${bubblewashData["B4"].isActive?"bubble-active":""}`} onClick={()=>handleBubblePopShow("B4",false)}/></div>
                    <div className="col"> <img src={bblePurple} alt="Sensiwash Base" className={`img-fluid sensi-wash-bubble last-bubble-pad  ${bubblewashData["B5"].isActive?"bubble-active":""}`} onClick={()=>handleBubblePopShow("B5",false)} /></div>
                </div>          
                <div className="bbl-pop-pos">
                  {bubblePopShow&&<BubblePopUp handleClose ={handleBubblePopHide} popData={bubblePopData} popInvert={false}/>}
                </div>                
            </div>
        </div>
        <div className="row">
        <div className="sensi-wash-content-emoli">       
                <img src={bubbleBackBot} alt="" srcset=""  className="img-fluid sensi-wash-back-bot"/>
                <div className="bubble-row bble-emoli-grid-pos">
                <div className="col"> <img src={bblOrange} alt="Sensiwash Base" className={`img-fluid sensi-wash-bubble ${bubblewashData["B6"].isActive?"bubble-active":""}`} onClick={()=>handleBubblePopShow("B6",true)}/></div>              
                </div>
                <div className="bbl-pop-pos">   
                  {bubbleGrayPopShow&&<BubblePopUp handleClose ={handleBubbleGrayPopHide} popData={bubbleGrayPopData} popInvert={true}/>}
                </div> 
            </div>
       </div>
  </div>
)}

export default SensiWashBubble;