import { LANG, PAGE } from "./../../config/CONST.js";

export class TraslationService {
  constructor(lang, page) {
    this.langPath = null;
    this.langPage = page;

    switch (lang) {
      case LANG.DUTCH:
        this.langPath = "./Data/DUT/";
        break;
      case LANG.FRENCH:
        this.langPath = "./Data/FR/";
        break;
      case LANG.ESTONIAN:
        this.langPath = "./Data/EST/";
        break;
      case LANG.LATVIAN:
        this.langPath = "./Data/LTV/";
        break;
      case LANG.PORTUGESE:
        this.langPath = "./Data/POR/";
        break;
      case LANG.LITHUANIAN:
        this.langPath = "./Data/LIT/";
        break;
      case null:
      case LANG.GERMAN:
        this.langPath = "./Data/FR/";
        break;
      case LANG.ENGLISH:
        this.langPath = "./Data/EN/";
        break;
      case LANG.SWEDISH:
        this.langPath = "./Data/SWD/";
        break;
      case LANG.NORWEGIAN:
        this.langPath = "./Data/NRW/";
        break;
      default:
        break;
    }
  }

  getTranslation() {
    switch (this.langPage) {
      case PAGE.LAYOUT:
        return require(this.langPath + "layout.json");
      case PAGE.ABOUT:
        return require(this.langPath + "about.json");
      case PAGE.PRODUCT:
        return require(this.langPath + "products.json");
      case PAGE.SCIENCE:
        return require(this.langPath + "science.json");
      case PAGE.LEARN:
        return require(this.langPath + "learn.json");
      default:
        break;
    }
  }
}
