import "./Home.view.style.scss";
import { useEffect, useState, useContext } from "react";

import familyPic from "../../images/about-family_mob.png";
import sensiControlWash from "../../images/SensiControlBottle.png";
import sensiControlWashEur from "../../images/ProductImage/SensiControlBottle_EUR.png";
import sensiControlWashScnd from "../../images/ProductImage/SensiControlBottle_Scandinavia.png";

import Aaccordion from "./../../components/Accordion/About/Accordion.component";
import UpCurve from "./../../components/UpCurve/UpCurve.components";
import DownCurve from "./../../components/DownCurve/DownCurve.components";

import { CountryContext } from "./../../Contexts/country.context";
import { TraslationService } from "./../../Service/Language/TranslationService";
import { PAGE } from "./../../config/CONST";

const Home = () => {
  const [animate, setAnimate] = useState("");
  const { currentCountry } = useContext(CountryContext);

  let TS = new TraslationService(currentCountry.LANG, PAGE.ABOUT);
  const AboutLabels = TS.getTranslation();
  let productShot = sensiControlWash;
  let showLabStatement = false;
  // refer country codes in Modal/Country/Modal.component
  switch (currentCountry.COUNTRY) {
    case "POR":
    case "BEL":
    case "LUX":
    case "LVA":
    case "LTU":
    case "EST":
      productShot = sensiControlWashEur;
      break;
    case "NOR":
    case "SWE":
      productShot = sensiControlWashScnd;
      showLabStatement = true;
      break;
    default:
      productShot = sensiControlWashEur;
      break;
  }

  useEffect(() => {
    setAnimate("atop");
  }, []);

  return (
    <div className="about-container">
      <div className="about-family-container">
        <img
          src={familyPic}
          className="img-fluid"
          alt="Bepanthen Happy Family"
        />
      </div>
      <div className={`bepanthen-product ${animate}`}>
        <img
          src={productShot}
          alt="SensiControl Body Wash"
          // className="product-shot"
        />
      </div>
      <div className="about-intro-section">
        <UpCurve />
        <div className="about-intro-container">
          <div className="view-container">
            <div className="about-intro">
              <h2
                dangerouslySetInnerHTML={{
                  __html: AboutLabels.AboutMainHeading1,
                }}
              ></h2>
              <h2>{AboutLabels.AbouttMainHeading2}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: AboutLabels.AboutMainText,
                }}
              ></p>
            </div>
          </div>
        </div>
        <DownCurve />
        <div className="content-container">
          <div className="about-benifits">
            <h2>{AboutLabels.BenifitsHeading}</h2>
            <ul>
              {AboutLabels.BenifitsLabel.map((s) => (
                <li>{s}</li>
              ))}
            </ul>
          </div>
        </div>
        <UpCurve />
        <Aaccordion data={AboutLabels.Accordion} showLab={showLabStatement} />
        <DownCurve />
      </div>
    </div>
  );
};
export default Home;
