import "./AppBanner.scss";

import { useContext, useEffect, useState } from "react";
import { CountryContext } from "./../../Contexts/country.context";

import BpModal from "../Modal/Country/Modal.component";
import BpLangModal from "../Modal/Language/Modal.component";

import { ReactComponent as Logo } from "./../../images/svg/logo.svg";
import { ReactComponent as AppLogo } from "./../../images/svg/AppLogo.svg";
import globe from "../../images/lang_Globe.png";

import { LANG } from "../../config/CONST";

const AppBanner = () => {
  let countryLangMapData = [
    {
      country: "POR",
      lang: [
        { langCode: LANG.PORTUGESE, langLabel: "Portugese", isCurrent: false },
      ],
    },
    {
      country: "BEL",
      lang: [
        { langCode: LANG.FRENCH, langLabel: "French", isCurrent: false },
        { langCode: LANG.DUTCH, langLabel: "Dutch", isCurrent: false },
      ],
    },
    {
      country: "LUX",
      lang: [
        { langCode: LANG.FRENCH, langLabel: "French", isCurrent: false },
        //{ langCode: LANG.FRENCH, langLabel: "German", isCurrent: false },
      ],
    },
    {
      country: "LVA",
      lang: [{ langCode: LANG.LATVIAN, langLabel: "Latvia", isCurrent: false }],
    },
    {
      country: "SWE",
      lang: [
        { langCode: LANG.SWEDISH, langLabel: "Swedish", isCurrent: false },
      ],
    },
    {
      country: "NOR",
      lang: [
        { langCode: LANG.NORWEGIAN, langLabel: "Norwegian", isCurrent: false },
      ],
    },
  ];

  const { currentCountry, setCurrentCountry } = useContext(CountryContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [countryLangMap, setcountryLangMap] = useState(countryLangMapData);
  const [langShow, setLangShow] = useState(false);
  const handleLangClose = () => setLangShow(false);

  const handleLangShow = () => {
    if (
      countryLangMap.find((cntry) => cntry.country === currentCountry.COUNTRY)
        .lang.length > 1
    ) {
      setLangShow(true);
    }
  };

  const handleLangSelection = (langCode) => {
    console.log("L : " + langCode + " CC :" + currentCountry.COUNTRY);
    setCurrentCountry({ LANG: langCode, COUNTRY: currentCountry.COUNTRY });

    const updateLangState = countryLangMap.map((cntry) => {
      if (cntry.country === currentCountry.COUNTRY) {
        return {
          ...cntry,
          lang: cntry.lang.map((lng) => {
            lng.isCurrent = false;
            if (lng.langCode === currentCountry.LANG) {
              return { ...lng, isCurrent: true };
            } else {
              return { ...lng, isCurrent: false };
            }
          }),
        };
      } else {
        return cntry;
      }
    });
    setcountryLangMap(updateLangState);
  };

  useEffect(() => {
    if (currentCountry.COUNTRY != null) {
      console.log("Country is " + currentCountry.COUNTRY);
      console.log("Lang is " + currentCountry.LANG);
    } else {
      setShow(true);
    }
  }, [currentCountry]);

  return (
    <nav className="appBanner">
      <div className="nav-container">
        <span className="lang-select-container" onClick={handleLangShow}>
          <span>
            <img src={globe} alt="" className="img-fluid" />
          </span>
          <span className="lang-select">{currentCountry.COUNTRY}</span>
        </span>
        <span className="app-logo">{/* <AppLogo /> */}</span>
        <Logo />
      </div>

      <BpModal show={show} handleClose={handleClose} title={"Select Country"} />

      <BpLangModal
        show={langShow}
        handleClose={handleLangClose}
        title={"Select Language"}
        handleLangSelect={handleLangSelection}
        curLang={currentCountry.LANG}
        langData={
          currentCountry
            ? countryLangMap.find(
                (langMap) => langMap.country === currentCountry.COUNTRY
              )
            : null
        }
      />
    </nav>
  );
};

export default AppBanner;
