import "./ByButton.componenets.style.scss";

import Button from "react-bootstrap/Button";

const ByButton = (props) => (
  <Button className="by-btn" onClick={props.handleClick}>
    {props.btnText}
  </Button>
);

export default ByButton;
