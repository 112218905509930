import { useContext } from "react";
import { Outlet } from "react-router-dom";

import { LANG, PAGE, APPROVAL_CODE } from "./config/CONST";
import { TraslationService } from "./Service/Language/TranslationService";

import { CountryContext } from "./Contexts/country.context";
import AppBanner from "./components/AppBanner/AppBaner";
import BottomNavigation from "./components/BottomNavigation/BottomNavigation";
import "./css/App.scss";

function App() {
  const { currentCountry } = useContext(CountryContext);

  let TS = null;
  let showApproval = false;
  let approvCode = "";
  let showApprovalText = false;
  if (currentCountry.LANG == null) {
    TS = new TraslationService(LANG.ENGLISH, PAGE.LAYOUT);
  } else {
    TS = new TraslationService(currentCountry.LANG, PAGE.LAYOUT);
  }
  switch (currentCountry.COUNTRY) {
    case "NOR":
      approvCode = APPROVAL_CODE.NORWAY;
      showApproval = true;
      break;
    case "SWE":
      approvCode = APPROVAL_CODE.SWEDEN;
      showApproval = true;
      break;
    case "LTU":
      approvCode = APPROVAL_CODE.LITHUANIA;
      showApproval = true;
      showApprovalText = true;
      break;
    case "EST":
      approvCode = APPROVAL_CODE.ESTONIA;
      showApproval = true;
      showApprovalText = true;
      break;
    case "LVA":
      approvCode = APPROVAL_CODE.LATVIA;
      showApproval = true;
      showApprovalText = true;
      break;
    default:
      showApproval = false;
      break;
  }

  console.log("From the APP Component : " + currentCountry.LANG);
  const layoutLabels = TS.getTranslation();

  return (
    <div className="App bayer">
      <AppBanner />
      <Outlet />
      <BottomNavigation
        navLabels={layoutLabels}
        showApproveCode={showApproval}
        approvalCode={approvCode}
        approvalText={showApprovalText}
      />
    </div>
  );
}

export default App;
