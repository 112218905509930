import "./Model.style.scss";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";


import { ReactComponent as Tick } from "../../../images/svg/Tick.svg";

const BpLangModal = ({ show, handleClose,langData,handleLangSelect,curLang, ...props }) => {


  return (
    <>
      <Modal show={show} onHide={handleClose} keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {langData&&<ListGroup variant="flush">
            {
              langData.lang.map((lang) => (
                <ListGroup.Item
                  key={lang.langCode}
                  onClick={() => handleLangSelect(lang.langCode)}
                >
                  <div className="coutry-row-wraper">
                    <div className="coutry-item">{lang.langLabel}</div>
                    <div className="coutry-item">
                      {(lang.langCode===curLang) && <Tick />}
                    </div>
                  </div>
                </ListGroup.Item>
              ))
            
            }
          </ListGroup>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BpLangModal;
