import "./Learn.view.style.scss";
import { useContext } from "react";
import { CountryContext } from "./../../Contexts/country.context";
import { PAGE, LANG } from "../../config/CONST";
import { TraslationService } from "./../../Service/Language/TranslationService";

// import ecdescImg from "./../../images/ec-dec.png";
import ByButton from "./../../components/ByButton/ByButton.component";
import familyPic from "../../images/about-family_mob.png";
import DownCurve from "./../../components/DownCurve/DownCurve.components";

const Learn = () => {
  const { currentCountry } = useContext(CountryContext);
  const openURL = (url) => {
    const targetURL = url;
    const openWindow = () => {
      window.open(targetURL, "_blank");
    };
    return openWindow;
  };

  let handleOpenURL = null;

  switch (currentCountry.LANG) {
    case LANG.PORTUGESE:
      handleOpenURL = openURL(
        "https://www.bepanthene.pt/saiba-mais-sobre-a-sua-pele/eczema"
      );
      break;
    case LANG.SWEDISH:
      handleOpenURL = openURL("https://www.bepanthen.se/hudproblem/eksem/");
      break;
    case LANG.NORWEGIAN:
      handleOpenURL = openURL(
        "https://www.bepanthen.no/products/bepanthen-sensicontrol-dusjsape/"
      );
      break;
    case LANG.GERMAN:
    case LANG.FRENCH:
      handleOpenURL = openURL("https://www.bepanthen.be/fr/eczema");
      break;
    case LANG.DUTCH:
      handleOpenURL = openURL("https://www.bepanthen.be/nl/eczeem");
      break;
    case LANG.LITHUANIAN:
      handleOpenURL = openURL("https://www.bepanthen.lt/bepanthen-produktai/");
      break;
    case LANG.LATVIAN:
      handleOpenURL = openURL(
        "https://www.bepanthen.lv/?gclid=CjwKCAjw46CVBhB1EiwAgy6M4gg1sC-CNTX3DInFcQUqPWnKNULDF2au8tHV04m1DmGY1x361vd07xoCaZ8QAvD_BwE&gclsrc=aw.ds"
      );
      break;
    case LANG.ESTONIAN:
      handleOpenURL = openURL("https://www.bepanthen.ee/sinu-nahk/ekseem/");
      break;
    default:
      break;
  }
  let TS = new TraslationService(currentCountry.LANG, PAGE.LEARN);
  const learnMore = TS.getTranslation();

  return (
    <>
      <div className="about-family-container">
        <img
          src={familyPic}
          className="img-fluid"
          alt="Bepanthen Happy Family"
        />
      </div>
      <div className="learn-container">
        <DownCurve curvStyle="learn-down-curv" />
        {/* <div className="learn-brand-title">
          <h2 className="title-bold">Eczema</h2>
        </div> */}
        <div className="learn-content-container">
          <div className="learn-desc">
            {/* <img src={ecdescImg} alt="Eczema Description" /> */}
            {/* <p>
              Eczema is a chronic inflammatory skin disease, considered one of
              the most prevalent pathologies in the pediatric population.
            </p> */}
            <p
              dangerouslySetInnerHTML={{
                __html: learnMore.learnMoreText,
              }}
            ></p>
          </div>
          <ByButton
            btnText={learnMore.learnMoreButtonText}
            handleClick={handleOpenURL}
          />
        </div>
      </div>
    </>
  );
};
export default Learn;
