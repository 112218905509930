import { createContext, useState } from "react";

export const CountryContext = createContext({
  currentCountry: { LANG: null, COUNTRY: null },
});

export const CountryProvider = ({ children }) => {
  const [currentCountry, setCurrentCountry] = useState({
    LANG: null,
    COUNTRY: null,
  });
  const value = { currentCountry, setCurrentCountry };
  return (
    <CountryContext.Provider value={value}>{children}</CountryContext.Provider>
  );
};
