import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/index.scss";

import reportWebVitals from "./reportWebVitals";
import { CountryProvider } from "./Contexts/country.context";
import App from "./App";
import Home from "./views/Home/Home.view";
import Product from "./views/Product/Product.view";
import Science from "./views/Science/Science.view";
import Learn from "./views/Learn/Learn.view";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <CountryProvider>
              <App />
            </CountryProvider>
          }
        >
          <Route index element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/science" element={<Science />} />
          <Route path="/learn" element={<Learn />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
